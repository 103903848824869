import { API } from "../../../utils";

export const getCompletedOrdersList = async (id, query) => {
  const url = `bump-screen/${id}/completed`;
  const res = await API.get(url, query);
  return res.data;
};

export const getPreparingOrdersList = async (id) => {
  const res = await API.get(`bump-screen/${id}/preparing`);
  return res.data;
};

export const getParkedOrdersList = async (id) => {
  const res = await API.get(`bump-screen/${id}/parked`);
  return res.data;
};

export const changeTicketStatus = async (id, status) => {
  const res = await API.post(`${id}/${status}`);
  return res.data;
};

export const changeTicketProductStatus = async (id, productId, status) => {
  const res = await API.post(`${id}/product/${productId}/${status}`);
  return res.data;
};

export const recallLastParkedOrder = async (id) => {
  const res = await API.post(`bump-screen/${id}/recall-last`);
  return res.data;
};

export const recallTicketWithdId = async (id) => {
  const res = await API.post(`${id}/recall/ticket`);
  return res.data;
};

export const recallAllParkedTickets = async (id) => {
  const res = await API.post(`bump-screen/${id}/recall`);
  return res.data;
};

export const resetAuthentication = async (id) => {
  const res = await API.get(`bump-screen/${id}/unpair`);
  return res.data;
};

export const getBumpscreenDetails = async (id) => {
  const res = await API.get(`bump-screen/${id}`);
  return res.data;
};
