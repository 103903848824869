import React, { useEffect } from "react";
import {
  Button,
  CircularProgress,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import { OrderItem } from "../components";

export function KOTList(props) {
  const {
    data,
    state,
    scrollRef,
    fontSize,
    handleChangePage,
    handleClickOpen,
    handleClose,
    onChangeTicketStatus,
    onChangeProductStatus,
    updateCounter,
  } = props;
  const { orderList, page, isOpenid, isBusy, isReCallLastBusy, busyCardId } =
    state;

  const trimStart = (page - 1) * 8;
  const trimEnd = trimStart + 8;

  if (isBusy) {
    return (
      <div className="loader">
        <CircularProgress />
      </div>
    );
  }

  return (
    <Stack
      ref={scrollRef}
      sx={{
        flexDirection: "column",
        flexWrap: "wrap",
        alignContent: "flex-start",
        gap: "12px",
        height: "100%",
        backgroundColor: "#F3F3F3",
        overflowX: "auto",
        overflowY: "hidden",
        height: "calc(100vh - 64px)",
        p: "10px",
        m: "-10px",
        scrollBehavior: "smooth",
      }}
    >
      {orderList.map((data, index) => {
        return (
          <OrderItem
            key={data.id}
            data={data}
            index={index}
            fontSize={fontSize}
            isOpenid={isOpenid}
            busyCardId={busyCardId}
            handleClose={handleClose}
            handleClickOpen={handleClickOpen}
            onChangeTicketStatus={onChangeTicketStatus}
            onChangeProductStatus={onChangeProductStatus}
            updateCounter={updateCounter}
          />
        );
      })}
    </Stack>
  );
}
