import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/scss/index.scss";
import { Master } from "./master/Master";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./assets/scss/_general.scss";

import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import { AppScopeProvider } from "./shared";

process.env !== "development" &&
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [new BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.REACT_APP_SENTRY_ENV_KEY,
  });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <AppScopeProvider>
      <Master />
    </AppScopeProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
