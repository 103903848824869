import { authentication } from "../api";
import { useImmer } from "use-immer";
import { useContext } from "react";
import { AppStateContext } from "../../../shared";
import { useNavigate } from "react-router-dom";

export const useAuthenticate = () => {
  const navigate = useNavigate();
  const { setAppState } = useContext(AppStateContext);
  const [state, setState] = useImmer({
    pairing_code: "",
    open: false,
    isBusy: false,
    errorMessage: "",
  });

  const onChange = (e) => {
    setState((draft) => {
      draft.pairing_code = e.target.value;
    });
  };

  const handleClose = () => {
    setState((draft) => {
      draft.open = false;
    });
  };

  const onSubmit = async () => {
    const { pairing_code } = state;
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await authentication({ pairing_code });
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
        });
        setAppState((draft) => {
          draft.data = res.data;
        });
        navigate("/kot");
      } else {
        setState((draft) => {
          draft.open = true;
          draft.errorMessage = res.message;
          draft.isBusy = false;
        });
      }
    } catch (error) {
      setState((draft) => {
        draft.open = true;
        draft.errorMessage = error.response.data.message;
        draft.isBusy = false;
      });
    }
  };

  return { state, onChange, handleClose, onSubmit };
};
