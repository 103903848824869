import moment from "moment";
import React, { useContext, useEffect } from "react";
import { useImmer } from "use-immer";
import qs from "qs";
import {
  getParkedOrdersList,
  recallAllParkedTickets,
  recallTicketWithdId,
} from "../api/kotApi";
import { AppStateContext } from "../../../shared";
import { calculateTimeDiff, errorMessage } from "../../../utils";

export const useParkedOrdersList = () => {
  const {
    appState: { data },
  } = useContext(AppStateContext);
  const [state, setState] = useImmer({
    isBusy: false,
    data: [],
    isBusyId: null,
    isRecallButtonBusy: false,
    searchText: "",
  });

  useEffect(() => {
    getList({ searchText: null });
  }, []);

  const getList = async ({
    searchText = null,
    start_date = moment.utc().format("YYYY-MM-DD"),
    end_date = moment.utc().format("YYYY-MM-DD"),
  }) => {
    let params = {};
    if (searchText) {
      params = { ...params, search: searchText };
    }
    if (start_date) {
      params = { ...params, start_date };
    }
    if (end_date) {
      params = { ...params, end_date };
    }
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getParkedOrdersList(data.id);
      if (res.data) {
        setState((draft) => {
          draft.data = res.data.map((item) => ({
            ...item,
            counter: calculateTimeDiff(item.start_time),
          }));
          draft.isBusy = false;
        });
      }
    } catch (err) {
      console.log(err);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };

  const moveToOrders = async (id) => {
    try {
      setState((draft) => {
        draft.isBusyId = id;
      });
      const res = await recallTicketWithdId(id);
      if (res.success) {
        setState((draft) => {
          draft.data = draft.data.filter((item) => item.id !== id);
          draft.isBusyId = null;
        });
      } else {
        errorMessage(res.message);
        setState((draft) => {
          draft.isBusyId = null;
        });
      }
    } catch (err) {
      console.log(err);
      errorMessage(err.response.message);
      setState((draft) => {
        draft.isBusyId = null;
      });
    }
  };

  const recallAllParkedOrders = async (id) => {
    try {
      setState((draft) => {
        draft.isRecallButtonBusy = true;
      });
      const res = await recallAllParkedTickets(data.id);
      if (res.success) {
        setState((draft) => {
          draft.data = [];
          draft.isRecallButtonBusy = false;
        });
      } else {
        errorMessage(res.message);
        setState((draft) => {
          draft.isRecallButtonBusy = false;
        });
      }
    } catch (err) {
      console.log(err);
      errorMessage(err.response.message);
      setState((draft) => {
        draft.isRecallButtonBusy = false;
      });
    }
  };

  const onSearchChange = (text) => {
    setState((draft) => {
      draft.searchText = text;
    });
  };

  const filterData = (data, searchText) => {
    const result = data.filter((item) => {
      const { token_number, provider, customer_name, user_name, sale_type } =
        item;
      const text = searchText.toLowerCase();
      return (
        token_number?.toLowerCase().includes(text) ||
        provider?.toLowerCase().includes(text) ||
        customer_name?.toLowerCase().includes(text) ||
        user_name?.toLowerCase().includes(text) ||
        sale_type?.toLowerCase().includes(text)
      );
    });
    return result;
  };

  const updateCounterParkedOrders = (data) => {
    setState((draft) => {
      draft.data = data.map((item) => ({
        ...item,
        counter: calculateTimeDiff(item.start_time),
      }));
    });
  };

  return {
    state,
    moveToOrders,
    recallAllParkedOrders,
    onSearchChange,
    filterData,
    updateCounterParkedOrders,
  };
};
