import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AppStateContext } from "../contexts";

export const ProtectedRoute = ({ id }) => {
  const {
    appState: { data, initialLoad },
  } = useContext(AppStateContext);
  const isAllowed = data.id;
  return !initialLoad && (isAllowed ? <Outlet /> : <Navigate to="/" replace />);
};
