import moment from "moment";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";



export const successMessage = async (text) => {
  if (text === null || text === undefined || text === "")
    text = "Data saved successfully!";
  Toastify({
    text: text,
    duration: 1000,
    close: true,
    gravity: "bottom",
    position: "right",
    avatar: "/images/success_toast.png",
    style: {
      background: "#388e3c",
    },
  }).showToast();
};

export const infoMessage = async (text) => {
  if (text === undefined || text === "") return;
  Toastify({
    text: text,
    duration: 1000,
    close: true,
    gravity: "bottom",
    position: "right",
    avatar: "/images/error_toast.png",
    style: {
      background: "#388e3c",
    },
    selector: "toastMessage",
  }).showToast();
};

export const errorMessage = async (text) => {
  if (text === null || text === undefined || text === "") text = "Error";
  Toastify({
    text: text,
    duration: 1500,
    close: true,
    gravity: "bottom",
    position: "right",
    avatar: "/images/error_toast.png",
    style: {
      background: "#d32f2f",
    },
  }).showToast();
};

export const compareCounter = (counter) => {
  const currentCounter = moment(counter, "HH:mm:ss");
  const diff = currentCounter.diff(moment("00:03:00", "HH:mm:ss"), "seconds");
  if (diff < 0) {
    return true;
  } else {
    return false;
  }
};

export const saleTypeColor = (data) => {
  if (data.status === "cancelled") {
    return "#666666"
  } else if (data.channel_id === 100) {
    return data.sale_type_id === 104 ? "#800020" : "#005437";
  } else if (data.channel_id === 101) {
    return '#3E2723'
  } else {
    return '#0D47A1'
  }
}

export const displayCounterInFormat = (counter) => {
  const parts = counter.split(":");
  if (Number(parts[0]) < 1) {
    return `${parts[1]}:${parts[2]}`;
  } else {
    return counter;
  }
};

export const calculateTimeDiff = (orderTime) => {
  const now = moment.utc();
  const orderTimeInformat = moment.tz(orderTime, "HH:mm:ss", "UTC");
  const diff = moment.tz(now.diff(orderTimeInformat), "UTC").format("HH:mm:ss");
  return diff;
};
