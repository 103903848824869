import { useEffect } from "react";
import { useImmer } from "use-immer";
import { API, errorMessage } from "../../utils";

export const useAppScope = () => {
  const [appState, setAppState] = useImmer({
    initialLoad: true,
    data: {},
    summary: {},
    fontSize: 0
  });

  useEffect(() => {
    const localStorageData = JSON.parse(localStorage.getItem("kot-data"));
    const fontLocalStorageData = JSON.parse(localStorage.getItem("kot-font"));
    if (localStorageData !== null) {
      setAppState((draft) => {
        draft.data = localStorageData;
        draft.fontSize = fontLocalStorageData;
      });
    }
    setAppState((draft) => {
      draft.initialLoad = false;
    });
  }, []);

  const getSummaryData = async (id) => {
    try {
      const res = await API.get(`bump-screen/${id}/summary/list`);
      setAppState((draft) => {
        draft.summary = res.data.data;
      });
    } catch (err) {
      errorMessage(err.response.message);
    }
  };

  useEffect(() => {
    if (appState.data.id) {
      localStorage.setItem("kot-data", JSON.stringify(appState.data));
      getSummaryData(appState.data.id);
    }
  }, [appState.data]);

  useEffect(() => {
      localStorage.setItem("kot-font", JSON.stringify(appState.fontSize));
  }, [appState.fontSize]);

  return { appState, setAppState };
};
