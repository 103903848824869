import { useContext, useEffect } from "react";
import { useImmer } from "use-immer";
import { getCompletedOrdersList, recallTicketWithdId } from "../api/kotApi";
import { AppStateContext, useActivityLog } from "../../../shared";
import moment from "moment";
import { errorMessage } from "../../../utils";

export const useCompletedOrdersList = () => {
  const {
    appState: { data },
    appState,
  } = useContext(AppStateContext);

  const { addActivityLog } = useActivityLog();
  const [state, setState] = useImmer({
    isBusy: false,
    data: [],
    searchText: "",
    isBusyId: null,
    isChanged: false,
    date: [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ],
    isOpen: false,
  });

  useEffect(() => {
    getList({
      searchText: null,
      start_date: moment(state.date[0].startDate).format("YYYY-MM-DD"),
      end_date: moment(state.date[0].endDate).format("YYYY-MM-DD"),
    });
  }, []);

  const getList = async ({
    searchText = null,
    start_date = moment.utc().format("YYYY-MM-DD"),
    end_date = moment.utc().format("YYYY-MM-DD"),
  }) => {
    let params = {};
    if (searchText) {
      params = { ...params, search: searchText };
    }
    if (start_date) {
      params = { ...params, start_date };
    }
    if (end_date) {
      params = { ...params, end_date };
    }
    const query = {
      params,
    };
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getCompletedOrdersList(data.id, query);
      if (res.data) {
        setState((draft) => {
          draft.data = res.data;
          draft.isBusy = false;
        });
      }
    } catch (err) {
      console.log(err);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };
  const activityListRecall = (res) => {
    const newData = res?.data;
    const { order_id, date, token_number } = newData;
    const parameterData = {
      TicketNumber: token_number,
      OrderId: order_id,
      StoreName: appState.data.store_name,
      BumpScreenName: appState.data.name,
    };
    addActivityLog({
      templateId: 16,
      parameters: parameterData,
      uniqueParameter: order_id,
      data: {
        newLogData: newData,
        oldLogData: {},
      },
    });
  };
  const moveToOrders = async (id) => {
    try {
      setState((draft) => {
        draft.isBusyId = id;
      });
      const res = await recallTicketWithdId(id);
      if (res.success) {
        activityListRecall(res);
        setState((draft) => {
          draft.data = draft.data.filter((item) => item.id !== id);
          draft.isBusyId = null;
        });
      } else {
        errorMessage(res.message);
        setState((draft) => {
          draft.isBusyId = null;
        });
      }
    } catch (err) {
      console.log(err);
      errorMessage(err.response.message);
      setState((draft) => {
        draft.isBusyId = null;
      });
    }
  };

  const onSearchChange = (text) => {
    setState((draft) => {
      draft.searchText = text;
    });
  };

  const filterData = (data, searchText) => {
    const result = data.filter((item) => {
      const { token_number, provider, customer_name, user_name, sale_type } =
        item;
      const text = searchText.toLowerCase();
      return (
        token_number?.toLowerCase().includes(text) ||
        provider?.toLowerCase().includes(text) ||
        customer_name?.toLowerCase().includes(text) ||
        user_name?.toLowerCase().includes(text) ||
        sale_type?.toLowerCase().includes(text)
      );
    });
    return result;
  };

  const onChangeDate = (e) => {
    setState((draft) => {
      draft.date = e;
      draft.isChanged = true;
    });
  };

  const handleIsOpen = (e) => {
    setState((draft) => {
      draft.isOpen = e;
    });
  };

  useEffect(() => {
    if (!state.isOpen && state.isChanged) {
      setState((draft) => {
        draft.isChanged = false;
      });
      getList({
        start_date: moment(state.date[0].startDate).format("YYYY-MM-DD"),
        end_date: moment(state.date[0].endDate).format("YYYY-MM-DD"),
      });
    }
  }, [state.isOpen]);

  return {
    state,
    moveToOrders,
    onSearchChange,
    filterData,
    onChangeDate,
    handleIsOpen,
  };
};
