import React, { useContext, useEffect, useRef } from "react";
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import version from "../../../../package.json";
import { useAuthenticate } from "../hooks/useAuthenticate";
import moment from "moment";
import tz from "moment-timezone";
import { AppStateContext } from "../../../shared";
import { useNavigate } from "react-router-dom";

export function Login() {
  const {
    appState: { data, initialLoad },
  } = useContext(AppStateContext);
  const navigate = useNavigate();
  const isLoggedIn = data.id;
  const { state, onChange, handleClose, onSubmit } = useAuthenticate();
  const { isBusy, pairing_code, open } = state;
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/kot");
    }
  }, [isLoggedIn]);

  if (isBusy) {
    return (
      <div className="loader">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
        className="landingWrap "
      >
        <div className="sideStep rightBorder">
          <h1>Welcome</h1>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ligula
            lorem sed ut sed viverra lacus enim.
          </p> */}
          <div className="step">
            <span>1</span>Log into the BTILL POS admin panel
          </div>
          <div className="step">
            <span>2</span>Go to Bump Screens tab under the required store
          </div>
          <div className="step">
            <span>3</span>Copy the Pairing Code of the bump screen and enter
            here
          </div>
          {/* <div className="step">
            <span>4</span>Enter your pairing code here
          </div> */}
        </div>
        <div className="sideStep">
          <div class="bfitBag">
            <img src="/images/launcherIcon.svg" className="light" />
            <img src="/images/launcherIcon_dark.svg" className="dark" />
          </div>
          <div className="bfittext">BTILL KDS</div>
          <label className="inputlabel">
            Enter the 6-digit pairing code to connect to a display.
          </label>
          <input
            maxLength={6}
            className="textInput"
            placeholder="Pairing Code"
            value={pairing_code}
            ref={inputRef}
            onChange={(e) => onChange(e)}
          />
          <button
            className="btn"
            type="submit"
            disabled={pairing_code === "" || isBusy}
          >
            Enter
          </button>
          <div className="version"> version {version.version}</div>
        </div>
      </form>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {state.errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
