import React from "react";
import { Box, Stack, Typography } from "@mui/material";



export const ProductItemsByCategory = (props) => {
  const { data, value, fontSize, onChangeProductStatus, isCancelled } = props;

  return (
    <>
      <Box
        sx={{
          bgcolor: "#1560D414",
          p: "6px 10px"
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: `calc(1rem + ${fontSize}%)`,
            fontWeight: 600
          }}
        >
          {value.category_name}
        </Typography>
      </Box>
      {value.products.map((value, i) => {
        const { id, status, quantity } = value;
        return (
          <>
            <Stack
              p="10px"
              // borderBottom="1px solid #E6E6E6"
              // borderLeft="1px solid #E6E6E6"
              key={i}
              width="290px"
              onClick={() => {
                const updateStatus =
                  status === "prepared" ? "preparing" : "prepared";
                onChangeProductStatus(data.id, id, updateStatus);
              }}
            >
              <Stack direction="row" gap="6px">
                <Typography
                  variant="h6"
                  sx={{
                    lineHeight: "normal",
                    color: isCancelled ? "#808080" : status === "prepared" ? `#8E8E93` : `#000`,
                    textDecorationLine:
                      status === "prepared" ? `line-through` : `none`,
                    minWidth: "fit-content",
                    fontSize: `calc(1.125rem + ${fontSize}%)`,
                  }}
                >
                  {quantity} x
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    lineHeight: "normal",
                    color: isCancelled ? "#808080" : status === "prepared" ? `#747479` : `#000`,
                    textDecorationLine:
                      status === "prepared" ? `line-through` : `none`,
                    fontSize: `calc(1.125rem + ${fontSize}%)`,
                  }}
                >
                 {data.display_product_alias ? value.product_alias : value.product_name}
                </Typography>
              </Stack>
              {value.ingredients.length > 0 && (
                <Stack gap="4px" p="5px 0px 0px 12px">
                  {value.ingredients.map((val, index) => (
                    <Typography
                      variant="h6"
                      key={index}
                      sx={{
                        lineHeight: "normal",
                        fontWeight: 500,
                        color:
                          isCancelled ? "#999999" : status === "prepared" ? `#747479` : `#D11B09`,
                        textDecorationLine:
                          status === "prepared" ? `line-through` : `none`,
                        fontSize: `calc(1.125rem + ${fontSize}%)`,
                      }}
                    >
                      {val.quantity} x {val.name}
                    </Typography>
                  ))}
                </Stack>
              )}
              {value.product_notes && (
                <Stack gap="4px" p="5px 0px 0px">
                  <Typography
                    sx={{
                      fontSize: `calc(17px + ${fontSize}%)`,
                      lineHeight: "normal",
                    }}
                  >
                    {value.product_notes}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </>
        )
      })}


    </>

  );
};
