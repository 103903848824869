import React, { useContext, useEffect } from "react";
import { AppStateContext } from "../../../shared";
import { getBumpscreenDetails, resetAuthentication } from "../api/kotApi";
import { useImmer } from "use-immer";

export const useKotSettings = () => {
  const {
    appState: { data },
  } = useContext(AppStateContext);
  const [state, setState] = useImmer({
    isBusy: false,
    data: [],
    isUnpairBusy: false,
    isUnpairOpen: false,
  });

  useEffect(() => {
    if (data.id) {
      getDetails(data.id);
    }
  }, [data.id]);

  const handleReset = async () => {
    try {
      setState((draft) => {
        draft.isUnpairBusy = true;
      });
      const res = await resetAuthentication(data.id);
      if (res.success) {
        localStorage.removeItem("kot-data");
        window.location.href = "/";
        setState((draft) => {
          draft.isUnpairBusy = false;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isUnpairBusy = false;
      });
    }
  };

  const getDetails = async (id) => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getBumpscreenDetails(id);
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.data = res.data;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };

  const toggleUnpair = (id = null) => {
    setState((draft) => {
      draft.isUnpairOpen = !draft.isUnpairOpen;
    });
  };

  const onConfirmUnpair = () => {
    handleReset();
  };

  return {
    handleReset,
    state,
    toggleUnpair,
    onConfirmUnpair,
  };
};
