import React from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useRowSelect,
  useExpanded,
} from "react-table";
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableHead,
  TableContainer,
  TableSortLabel,
  TablePagination,
  OutlinedInput,
  InputAdornment,
  Box,
  Paper,
  Stack,
  Checkbox,
  IconButton,
  Chip,
  Button,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

export const CustomTable = (props) => {
  const {
    columns,
    data,
    initialPage,
    minWidth = 800,
    maxHeight = "100%",
    tableTitle,
    headerRightContent = null,
    pagination = true,
  } = props;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    preGlobalFilteredRows,
    globalFilteredRows,
    state: { pageIndex, pageSize },
    rows,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: initialPage ?? 7,
        sortBy: [],
      },
      getSubRows: (row) => row.permissions || [],
    },

    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );
  const tableData = pagination ? page : rows;
  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            border: "1px solid #CCCCCC",
          }}
        >
          <TableContainer
            sx={{
              minWidth: minWidth,
              maxHeight: maxHeight,
              overflowX: "unset",
            }}
          >
            {tableTitle && (
              <CustomTableHeader
                tableTitle={tableTitle}
                headerRightContent={headerRightContent}
              />
            )}

            <Table
              {...getTableProps()}
              sx={{
                borderCollapse: "inherit",
                overflow: "hidden",
                maxHeight: "initial",
              }}
            >
              <TableHead>
                {headerGroups.map((headerGroup, i) => (
                  <TableRow key={i} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, index) => (
                      <TableCell
                        key={index}
                        sx={{
                          p: !column.padding && "10px",
                        }}
                        align={column?.align}
                        padding={column.padding || "normal"}
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        <>
                          {column.canSort ? (
                            <TableSortLabel
                              active={column.isSorted}
                              direction={column.isSortedDesc ? "dsc" : "asc"}
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  height: "15px",
                                  width: "15px",
                                },
                              }}
                            >
                              {column.render("Header")}
                            </TableSortLabel>
                          ) : (
                            <>{column.render("Header")}</>
                          )}
                        </>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              {preGlobalFilteredRows.length > 0 &&
              globalFilteredRows.length > 0 ? (
                <TableBody {...getTableBodyProps()}>
                  {tableData.map((row, i) => {
                    prepareRow(row);
                    return (
                      <TableRow key={i} {...row.getRowProps()}>
                        {row.cells.map((cell, i) => {
                          return (
                            <TableCell
                              key={i}
                              align={cell.column?.align}
                              padding={cell.column.padding || "normal"}
                              sx={{
                                borderBottom: "1px solid #E6E6E6",
                                p: !cell.column.padding && "8px",
                              }}
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={20}
                      sx={{ py: 2, backgroundColor: "#F7F7F7" }}
                    >
                      <Typography
                        align="center"
                        variant="body2"
                        color={"#666666"}
                      >
                        No data available in table
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {pagination && (
            <TablePagination
              rowsPerPageOptions={[7, 10, 20, 30, 40, 50]}
              component="div"
              count={rows.length}
              rowsPerPage={pageSize}
              page={pageIndex}
              onPageChange={(e, page) => gotoPage(page)}
              onRowsPerPageChange={(e) => setPageSize(e.target.value)}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export function CustomTableHeader({ tableTitle, headerRightContent }) {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        py: "16px",
        px: "24px",
        gap: "24px",
        borderBottom: "1px solid #E6E6E6",
        height: "88px",
      }}
    >
      <Typography align="center" variant="h4">
        {tableTitle}
      </Typography>
      {headerRightContent}
    </Stack>
  );
}
