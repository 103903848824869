import React, { useEffect, useRef } from "react";
import { ContentLayout, CustomTable } from "../../../shared";
import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  InputAdornment,
  OutlinedInput,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { useParkedOrdersList } from "../hooks/useParkedOrdersList";
import { LoadingButton } from "@mui/lab";
import { compareCounter } from "../../../utils";

export function ParkedOrdersList() {
  const navigate = useNavigate();
  const {
    state: { data, isBusy, isRecallButtonBusy, searchText },
    moveToOrders,
    recallAllParkedOrders,
    onSearchChange,
    filterData,
    updateCounterParkedOrders,
  } = useParkedOrdersList();

  useEffect(() => {
    var timer = setInterval(() => {
      data.length > 0 && updateCounterParkedOrders(data);
    }, 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  }, [data]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Token Number",
        accessor: "token_number",
        disableSortBy: true,
      },
      {
        Header: "Date & Time",
        accessor: "order_date",
        disableSortBy: true,
      },
      {
        Header: "Timer",
        accessor: "counter",
        Cell: ({ value }) => {
          return (
            <Chip
              avatar={
                <Avatar
                  alt="alaram"
                  src={"/images/alarm-white.svg"}
                  sx={{
                    width: "18px",
                  }}
                />
              }
              label={value}
              sx={{
                '& .MuiChip-label': { fontFamily: "SFMono" },
                background: `${compareCounter(value) === 1
                  ? "#14C587"
                  : compareCounter(value) === 2
                    ? "#FEA725"
                    : "#FF3B30"
                  }`,
                color: "#FFFFFF",
                gap: "6px",
              }}
            />
          );
        },
        disableSortBy: true,
      },
      {
        Header: "Order Type",
        accessor: "sale_type",
        disableSortBy: true,
      },
      {
        Header: "Provider",
        accessor: "provider",
        disableSortBy: true,
      },
      {
        Header: "Customer Name",
        accessor: "customer_name",
        disableSortBy: true,
      },
      {
        Header: "Staff Name",
        accessor: "user_name",
        disableSortBy: true,
      },

      {
        Header: " ",
        accessor: "id",
        align: "right",
        Cell: ({ row }) => {
          const id = row.original.id;
          return (
            <Button
              onClick={() => moveToOrders(id)}
              size="small"
              color="primary"
              sx={{
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "17px",
                bgcolor: "rgba(21, 96, 212, 0.08)"
              }}
            >
              Move To Preparing
            </Button>
          );
        },
      },
    ],
    []
  );

  if (isBusy) {
    return (
      <div className="loader">
        <CircularProgress />
      </div>
    );
  }

  return (
    <Stack direction="row" flexWrap="wrap" gap="16px">
      <CustomTable
        columns={columns}
        data={filterData(data, searchText)}
        tableTitle="Parked Tickets"
        headerRightContent={
          <Stack direction="row" gap="24px" alignItems="center">
            <OutlinedInput
              sx={{
                height: "48px",
                minWidth: "500px",
              }}
              value={searchText}
              onChange={(e) => onSearchChange(e.target.value)}
              placeholder="Search"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon size="large" />
                </InputAdornment>
              }
              fullWidth
            />

            <LoadingButton
              loading={isRecallButtonBusy}
              onClick={() => recallAllParkedOrders()}
              color="primary"
              sx={{
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "17px",
                bgcolor: "rgba(21, 96, 212, 0.08)",
                minWidth: "180px",
              }}
              disabled={!data.length > 0}
            >
              Move All To Preparing
            </LoadingButton>
          </Stack>
        }
      />
    </Stack>
  );
}
