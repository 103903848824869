import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Pagination,
  Stack,
  Typography,
  hexToRgb,
} from "@mui/material";
import moment from "moment";
import {
  compareCounter,
  saleTypeColor,
  displayCounterInFormat,
} from "../../../utils";
import { useImmer } from "use-immer";
import { keyframes } from "@mui/system";
import { ProductItems } from "./ProductItems";
import { ProductItemsByCategory } from "./ProductItemsByCategory";

const useIntersection = (element, rootMargin) => {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setState(entry.isIntersecting);
      },
      { rootMargin }
    );

    element.current && observer.observe(element.current);

    return () => element.current && observer.unobserve(element?.current);
  }, []);

  return isVisible;
};

export function OrderItem(props) {
  const shake = (color) => keyframes`
  0%, 49%{  background: ${color} }    
  50% ,100%{ background: ${color + "c2"} }
`;
  const [width, setWidth] = useImmer(290);
  const timer = useRef();
  const itemRef = useRef(null);
  const inViewport = useIntersection(itemRef, "0px");

  const {
    data,
    index,
    isOpenid,
    fontSize,
    handleClose,
    handleClickOpen,
    onChangeTicketStatus,
    onChangeProductStatus,
    updateCounter,
    busyCardId,
  } = props;

  useEffect(() => {
    if (inViewport) {
      var timer = setInterval(() => {
        updateCounter(data.id);
      }, 1000);
      return function cleanup() {
        clearInterval(timer);
      };
    }
  }, [data.id, inViewport]);

  const onClickHandler = (event, id) => {
    // clearTimeout(timer.current);

    // if (event.detail === 1) {
    //   timer.current = setTimeout(() => handleClickOpen(id), 300);
    // } else
    if (event.detail === 2) {
      onChangeTicketStatus("completed", id);
    }
  };

  const ref = useRef(null);
  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.scrollWidth || 290);
    }
  }, [ref.current]);

  const isCancelled = data.status === "cancelled" ? true : false

  return (
    <Box
      ref={itemRef}
      position="relative"
      sx={{
        height: "max-content",
        minHeight: "174px",
        maxHeight: "100%",
      }}
    >
      <Stack
        key={index}
        sx={{
          minWidth: "290px",
          minHeight: "174px",
          maxHeight: "100%",
          background: "#FFFFFF",
          outline: "1px solid #CCCCCC",
          // width: data.id === 561 && "580px"
        }}
      >
        <>
          <Stack
            sx={{
              background: saleTypeColor(data),
              animation: !compareCounter(data.counter)
                ? `${shake(saleTypeColor(data))} 2s linear infinite;`
                : "",
            }}
            onClick={(e) => onClickHandler(e, data.id)}
          >
            <Stack
              sx={{
                flexDirection: "row",
                // justifyContent: data.continued ? "flex-start" : "space-between",
                justifyContent: "space-between",
                alignItems: "center",
                p: " 6px 10px",
                gap: "10px",
              }}
            >
              <Stack>
                <Chip
                  label={data.token_number ?? ""}
                  sx={{
                    bgcolor: "#fff",
                    "& .MuiChip-label": {
                      fontWeight: "600",
                      fontSize: `calc(16px + ${fontSize}%)`,
                      lineHeight: "normal",
                      color: isCancelled ? "#808080" : "#000"
                    },
                  }}
                />
              </Stack>

              <Box>
                <Typography
                  variant="body3"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "8px",
                    backgroundColor: "#fff",
                    color: isCancelled ? "#808080" : "#40454C",
                    borderRadius: "16px",
                    paddingLeft: "6px",
                    fontWeight: 600,
                    lineHeight: "normal",
                  }}
                >
                  {data.sale_type}

                  <Chip
                    avatar={
                      <Avatar
                        alt="alaram"
                        src="/images/Alarm.svg"
                        sx={{
                          width: "18px",
                          filter: isCancelled && "brightness(0) saturate(100%) invert(26%) sepia(97%) saturate(0%) hue-rotate(29deg) brightness(96%) contrast(96%)"
                        }}
                      />
                    }
                    label={displayCounterInFormat(data.counter)}
                    sx={{
                      "& .MuiChip-label": {
                        fontFamily: "SFMono",
                        fontSize: `calc(14px + ${fontSize}%)`,
                        color: isCancelled ? "#808080" : "#000"
                      },
                      background: "#E6E6E6",
                      gap: "6px",
                    }}
                  />
                </Typography>
              </Box>
            </Stack>

            <>
              <Stack
                sx={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: "4px 10px",
                  height: "24px",
                }}
              >
                <Stack direction="row" gap="4px">
                  <img
                    alt="clock"
                    className="img-16"
                    src="/images/ic_customer.svg"
                  />
                  <Typography
                    variant="h5"
                    fontWeight={500}
                    lineHeight="19px"
                    color="#fff"
                    fontSize={`calc(16px + ${fontSize}%)`}
                    maxWidth={`${(width / 290) * 100}px`}
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {data.customer_name}
                  </Typography>
                  <Typography
                    variant="h5"
                    fontWeight={500}
                    lineHeight="19px"
                    color="#fff"
                    fontSize={`calc(16px + ${fontSize}%)`}
                  >
                    {data.table_number && `- ${data.table_number || ""}`}
                  </Typography>
                </Stack>
                <Stack direction="row" gap="4px">
                  <img alt="clock" className="img-16" src="/images/Clock.svg" />
                  <Typography
                    variant="subtitle2"
                    fontWeight={500}
                    lineHeight="17px"
                    color="#fff"
                    fontSize={`calc(14px + ${fontSize}%)`}
                  >
                    {moment(data.order_date).format("hh:mm:ss A")}
                  </Typography>
                </Stack>
              </Stack>
            </>
          </Stack>
          <Stack
            direction="row"
            sx={{
              padding: "8px",
              justifyContent: "space-between",
              gap: "8px",
              backgroundColor: "#E6E6E6",
            }}
          >
            <Button
              fullWidth
              sx={{
                height: "40px",
                borderRadius: "6px",
                color: "#1560D4",
                backgroundColor: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#0000000F",
                },
              }}
              disabled={isCancelled}
              onClick={() => onChangeTicketStatus("parked", data.id)}
            >
              Park
            </Button>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              sx={{ height: "40px", borderRadius: "6px" }}
              onClick={() => onChangeTicketStatus("completed", data.id)}
            >
              Serve
            </Button>
          </Stack>

          {/* order items List */}
          <Stack
            ref={ref}
            id="target-element"
            sx={{
              flexWrap: "wrap",
              height: "100%",
              overflow: "auto",
              width: `${width}px`,
            }}
          >
            <>
              {data.display_category_name ? data.product_by_category.map((value, index) => {
                return (
                  <ProductItemsByCategory
                    key={index}
                    data={data}
                    value={value}
                    fontSize={fontSize}
                    isCancelled={isCancelled}
                    onChangeProductStatus={onChangeProductStatus}
                  />
                );
              }) : data.products.map((value, index) => {
                return (
                  <ProductItems
                    key={index}
                    data={data}
                    value={value}
                    fontSize={fontSize}
                    isCancelled={isCancelled}
                    onChangeProductStatus={onChangeProductStatus}
                  />
                );
              })}
            </>
          </Stack>
          {data.order_notes && (
            <Stack p="10px" borderTop="" sx={{
              backgroundColor: isCancelled ? "#E6E6E6" : "#FFF4CE",
              borderTop: "1px solid #E6E6E6",
              p: "10px",
            }}>
              <Typography
                sx={{
                  fontSize: `calc(16px + ${fontSize}%)`,
                  lineHeight: "normal",
                }}
                color={isCancelled ? "#4D4D4D" : "#002470"}
              >
                {data.order_notes}
              </Typography>
            </Stack>
          )}
          {!!data.ticket_updated && (
            <Stack
              p="10px"
              sx={{
                backgroundColor: "#C42B1C",
              }}
              flexDirection="row"
              justifyContent="center"
            >
              <Typography variant="body3" sx={{ fontWeight: 500 }} color="#FFF">
                Ticket Updated
              </Typography>
            </Stack>
          )}
          {isCancelled && (
            <Stack
              p="6px"
              sx={{
                backgroundColor: "#C42B1C",
              }}
              flexDirection="row"
              justifyContent="center"
            >
              <Typography variant="body3" sx={{ fontWeight: 600 }} color="#FFF" textTransform="uppercase">
                order Cancelled!
              </Typography>
            </Stack>
          )}
          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              p: "10px",
              backgroundColor: isCancelled ? "#4D4D4D" : "#000"
            }}
          >
            <Stack
              sx={{
                flexDirection: "row",
                gap: "4px",
              }}
            >

              <img
                alt="badge"
                className="img-16"
                src="/images/ic_badge.svg"
              />
              <Typography
                variant="h5"
                color="#fff"
                fontSize={`calc(16px + ${fontSize}%)`}
              >
                {data.user_name}
              </Typography>
            </Stack>
            <Stack
              sx={{
                flexDirection: "row",
                gap: "4px",
              }}
            >
              <img
                alt="type"
                className="img-16"
                src="/images/ic_online.svg"
              />
              <Typography
                variant="subtitle2"
                fontWeight={500}
                lineHeight="17px"
                color="#fff"
                fontSize={`calc(0.875rem + ${fontSize}%)`}
              >
                {data.sales_channel}
              </Typography>
            </Stack>
          </Stack>
        </>
      </Stack>

      {busyCardId === data.id && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            backgroundColor: "rgba(0, 0, 0, 0.36)",
            zIndex: "1",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {/* {isOpenid === data.id && (
        <Stack
          sx={{
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            backgroundColor: "rgba(0, 0, 0, 0.36)",
            zIndex: "1",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          }}
          onClick={handleClose}
        >
          <Stack
            p="16px"
            gap="24px"
            bgcolor="#fff"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Button
              color="info"
              onClick={() => onChangeTicketStatus("parked", isOpenid)}
            >
              Park
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => onChangeTicketStatus("completed", isOpenid)}
            >
              Serve
            </Button>
          </Stack>
        </Stack>
      )} */}
    </Box>
  );
}
