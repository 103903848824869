import React, { useState, useEffect, useRef } from "react";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { Stack, Typography, Button } from "@mui/material";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Calendar } from "react-date-range";
import moment from "moment";
import { DateRangePicker } from 'react-date-range'
import format from 'date-fns/format'
import { addDays, isSameDay, startOfDay, endOfDay, differenceInCalendarDays } from 'date-fns'


export const CalenderDatePicker = (props) => {
  const { onChangeDate, range, showRange, isOpen, handleIsOpen } = props;

  // get the target element to toggle
  const refOne = useRef(null);

  useEffect(() => {
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);

  // hide dropdown on ESC press
  const hideOnEscape = (e) => {
    if (e.key === "Escape") {
      handleIsOpen(false);
    }
  };

  // Hide dropdown on outside click
  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      handleIsOpen(false);
    }
  };

  return (
    <div className="calendarWrap">
      <Stack
        sx={{
          flexDirection: "row",
          gap: "8px",
          padding: "12px 12px",
          backgroundColor: "#F3F3F3",
          borderRadius: "8px",
          minWidth: "253px",
          width: "max-content",
        }}
        onClick={() => handleIsOpen(!isOpen)}
      >
        <CalendarTodayIcon
          sx={{
            width: "24px",
            height: "24px",
          }}
        />
        <Typography variant="subtitle2" fontWeight={600}>
          {`${format(range[0].startDate, "MMM dd, yyyy")} - ${format(
            range[0].endDate,
            "MMM dd, yyyy"
          )}`}
        </Typography>
      </Stack>

      <div ref={refOne}>
        {isOpen && (
          <div className="calendarElement">
            <DateRangePicker
              onChange={(item) => onChangeDate([item.selection])}
              editableDateInputs={true}
              moveRangeOnFirstSelection={false}
              ranges={range}
              direction="vertical"
              months={1}
              staticRanges={[]}
              inputRanges={[]}
              renderStaticRangeLabel={false}
              showDateDisplay={false}
              color="#1560D4"
              maxDate={new Date()}
            />
          </div>
        )}
      </div>
    </div>
  );
};
