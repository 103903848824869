import axios from "axios";
import { ACTIVITY_LOG_URL } from "../../utils/apiConfig";
import { useContext } from "react";
import { AppStateContext } from "../../shared";

const LOGAPI = axios.create({
  baseURL: ACTIVITY_LOG_URL,
  headers: {
    projectKey: "B8TKX7",
  },
});

export const useActivityLog = () => {
  const { appState } = useContext(AppStateContext);
  const addActivityLog = ({
    templateId,
    parameters,
    uniqueParameter,
    data,
  }) => {
    const payload = {
      parameters,
      user: `sreen-${appState.data?.name}`,
      uniqueParameter,
      environment: process.env.REACT_APP_SENTRY_ENV_KEY,
      data,
    };
    LOGAPI.post(`External/${templateId}`, payload);
  };
  return { addActivityLog };
};
