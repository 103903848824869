import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, Typography, Avatar, Stack } from "@mui/material";

export const CustomeDialog = (props) => {
  const {
    open,
    handleClose,
    content = null,
    PaperProps,
    title,
    size = "sm",
    isHeader = true,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={PaperProps}
      sx={{
        maxHeight: "100%",
        "& + .pac-container": {
          zIndex: 18000,
        },
      }}
    >
      {isHeader && (
        <Stack direction="row" justifyContent="space-between">
          <Typography fontWeight={600} variant="body1">
            {title}
          </Typography>
          {CloseIcon && (
            <Avatar
              sx={{
                bgcolor: " rgba(0, 0, 0, 0.03)",
                position: "absolute",
                top: size === "md" ? "11px" : "6px",
                right: size === "md" ? "13px" : "8px",
                cursor: "pointer",
                width: size === "md" ? 46 : 40,
                height: size === "md" ? 46 : 40,
                "&:hover": {
                  bgcolor: "rgba(0,0,0, 0.08)",
                },
                zIndex: 2,
              }}
              variant="rounded"
              onClick={handleClose}
            >
              <CloseIcon
                sx={{
                  color: "#000",
                }}
              />
            </Avatar>
          )}
        </Stack>
      )}
      {content}
    </Dialog>
  );
};
