import React, { useEffect, useRef } from "react";
import { ContentLayout, CustomTable } from "../../../shared";
import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  InputAdornment,
  OutlinedInput,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { CalenderDatePicker } from "../components";
import { useCompletedOrdersList } from "../hooks/useCompletedOrdersList";
import { compareCounter } from "../../../utils";

export function CompletedOrdersList() {
  const navigate = useNavigate();
  const {
    state: { isBusy, data, isBusyId, searchText, date, isOpen },
    moveToOrders,
    onSearchChange,
    filterData,
    onChangeDate,
    handleIsOpen,
  } = useCompletedOrdersList();

  const columns = React.useMemo(
    () => [
      {
        Header: "  ",
        Cell: ({ cell }) => {
          return <img alt="badge" className="img-24" src="/images/Tick.svg" />;
        },
      },
      {
        Header: "Token Number",
        accessor: "token_number",
        disableSortBy: true,
      },
      {
        Header: "Date & Time",
        accessor: "order_date",
        disableSortBy: true,
      },
      {
        Header: "Timer",
        accessor: "completed_timer",
        Cell: ({ value }) => {
          return (
            <Chip
              avatar={
                <Avatar
                  alt="alaram"
                  src={
                    compareCounter(data.counter) === 1
                      ? "/images/YellowAlarm.svg"
                      : compareCounter(data.counter) === 2
                      ? "/images/YellowAlarm.svg"
                      : "/images/RedAlarm.svg"
                  }
                  sx={{
                    width: "18px",
                  }}
                />
              }
              label={value}
              sx={{
                "& .MuiChip-label": { fontFamily: "SFMono" },
                backgroundColor: "#E6E6E6",
                color: `${
                  compareCounter(data.counter) === 1
                    ? "#14C587"
                    : compareCounter(data.counter) === 2
                    ? "#FEA725"
                    : "#FF3B30"
                }`,
                gap: "6px",
              }}
            />
          );
        },
        disableSortBy: true,
      },
      {
        Header: "Order Type",
        accessor: "sale_type",
        disableSortBy: true,
      },
      {
        Header: "Provider",
        accessor: "provider",
        disableSortBy: true,
      },
      {
        Header: "Customer Name",
        accessor: "customer_name",
        disableSortBy: true,
      },
      {
        Header: "Staff Name",
        accessor: "user_name",
        disableSortBy: true,
      },

      {
        Header: " ",
        align: "right",
        Cell: ({ row }) => {
          const id = row.original.id;
          return (
            <LoadingButton
              onClick={() => {
                moveToOrders(id);
              }}
              loading={id === isBusyId}
              color="primary"
              size="small"
              sx={{
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "17px",
                bgcolor: "rgba(21, 96, 212, 0.08)",
              }}
            >
              Recall
            </LoadingButton>
          );
        },
      },
    ],
    []
  );

  if (isBusy) {
    return (
      <div className="loader">
        <CircularProgress />
      </div>
    );
  }

  return (
    <Stack direction="row" flexWrap="wrap" gap="16px">
      <CustomTable
        columns={columns}
        data={filterData(data, searchText)}
        tableTitle="Completed Tickets"
        headerRightContent={
          <Stack direction="row" gap="24px" alignItems="center">
            <OutlinedInput
              sx={{
                height: "48px",
                minWidth: "500px",
              }}
              value={searchText}
              onChange={(e) => onSearchChange(e.target.value)}
              placeholder="Search"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon size="large" />
                </InputAdornment>
              }
              fullWidth
            />

            <CalenderDatePicker
              onChangeDate={onChangeDate}
              range={date}
              isOpen={isOpen}
              handleIsOpen={handleIsOpen}
            />
          </Stack>
        }
      />
    </Stack>
  );
}
