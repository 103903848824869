import { Routes, Route } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { AppStateContext, ProtectedRoute, Theme } from "../shared";
import { Authentication, KOT } from "../modules";
import { useContext } from "react";
import { configureAbly } from "@ably-labs/react-hooks";

window.history.pushState(null, null, window.location.href);
window.history.back();
window.onpopstate = () => window.history.forward();

export function Master() {
  const {
    appState: { data },
  } = useContext(AppStateContext);

  data.id &&
    configureAbly({
      key: process.env.REACT_APP_ABLY_KEY,
      clientId: `${data.id}`,
    });

  return (
    <>
      <ThemeProvider theme={Theme}>
        <CssBaseline />
        <Routes>
          <Route element={<Authentication />} path={"/*"} />
          <Route element={<ProtectedRoute />}>
            <Route element={<KOT />} path={"/kot/*"} />
          </Route>
        </Routes>
      </ThemeProvider>
    </>
  );
}
