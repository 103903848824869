import moment from "moment";
import React, { useContext, useEffect, useRef } from "react";
import { useImmer } from "use-immer";
import { AppStateContext, useActivityLog } from "../../../shared";
import {
  calculateTimeDiff,
  errorMessage,
  successMessage,
} from "../../../utils";
import {
  changeTicketProductStatus,
  changeTicketStatus,
  getPreparingOrdersList,
  recallLastParkedOrder,
} from "../api/kotApi";

import { useChannel } from "@ably-labs/react-hooks";
import { useLocation, useParams } from "react-router-dom";
import NotificationAudio from "../../../assets/audio/notification.mp3";

export const useKotList = () => {
  const scrollRef = useRef(null);
  const { pathname } = useLocation();
  const isPreparing = pathname === "/kot";
  const {
    appState,
    appState: { data, fontSize },
  } = useContext(AppStateContext);
  const { addActivityLog } = useActivityLog();

  const [state, setState] = useImmer({
    isBusy: false,
    orderList: [],
    page: 1,
    isOpenid: null,
    isReCallLastBusy: false,
    busyCardId: null,
    timer: 0,
  });

  useEffect(() => {
    setInterval(() => {
      setState((draft) => {
        let newTime = draft.timer + 1;
        if (newTime % 900 === 0) {
          getPreparingData({ initialLoad: false });
          newTime = 0;
        }
        draft.timer = newTime;
      });
    }, 1000);
    return () => {
      clearInterval();
    };
  }, []);

  const playSound = () => {
    const audio = new Audio(NotificationAudio);
    audio.addEventListener("canplaythrough", (event) => {
      // the audio is now playable; play it if permissions allow
      audio.play();
    });
  };

  const addLog = (data) => {
    addActivityLog({
      templateId: 13,
      uniqueParameter: data.payload.order_id,
      parameters: {
        OrderId: data.payload.order_id,
        StoreName: appState.data.StoreName,
        BumpScreenName: appState.data.name,
        EventDateTime: new Date().toUTCString(),
      },
      data: {
        newLogData: data.payload,
        oldLogData: {},
      },
    });
  };

  const [channel, ably] = useChannel(
    `public:bump-screen-ticket.${data.id}`,
    (message) => {
      const { data } = message;
      if (data.event_type === "new-ticket" && data.payload.channel_id !== 100) {
        playSound();
      }
      getPreparingData({ initialLoad: false });
      addLog(data);
      setState((draft) => {
        draft.timer = 0;
      });
    }
  );

  const addNewTicketToList = (payload) => {
    setState((draft) => {
      draft.orderList = [
        ...draft.orderList,
        {
          ...payload,
          counter: calculateTimeDiff(payload.start_time),
        },
      ];
    });
  };

  const updateListOnEvents = (event_type, payload) => {
    const { order_id, products } = payload;
    const orderList = [...state.orderList];
    if (event_type === "ticket-remove") {
      setState((draft) => {
        draft.orderList = orderList.filter(
          (item) => item.order_id !== order_id
        );
      });
    } else if (event_type === "ticket-recall") {
      addNewTicketToList(payload);
    } else if (event_type === "new-ticket") {
      addNewTicketToList(payload);
    } else if (event_type === "product-update") {
    } else if (event_type === "ticket-update") {
      if (payload.status === "parked") {
      } else if ((payload.status = "preparing")) {
      }
    }
  };

  const [events, ablyevents] = useChannel(
    `public:bump-screen.${data.store_id}`,
    (message) => {
      const { event_type, payload } = message.data;
      if (data.id !== payload.bump_screen_id) {
        getPreparingData({ initialLoad: false });
        setState((draft) => {
          draft.timer = 0;
        });
      }
    }
  );

  useEffect(() => {
    if (isPreparing) {
      getPreparingData({ initialLoad: true });
    }
  }, [isPreparing]);

  const formateByCategory = (item) => {

    const groupedByCategory = item.products.reduce((acc, product) => {
      const { category_id, category_name } = product;
      let category = acc.find(cat => cat.category_id === category_id);

      if (!category) {
        category = {
          category_id,
          category_name,
          products: []
        };
        acc.push(category);
      }

      category.products.push(product);
      return acc;
    }, []);

    return groupedByCategory
  }

  const getPreparingData = async ({ initialLoad = false, type = "normal" }) => {
    try {
      if (initialLoad) {
        setState((draft) => {
          draft.isBusy = true;
        });
      }
      const res = await getPreparingOrdersList(data.id);
      if (res.data) {
        setState((draft) => {
          draft.orderList = res.data.map((item) => ({
            ...item,
            product_by_category: formateByCategory(item),
            counter: calculateTimeDiff(item.start_time),
          }));
          draft.isBusy = false;
        });
        if (type === "recallLast") {
          successMessage("Last completed ticket re-called successfully.");
        }
      }
    } catch (err) {
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };
  const handleChangePage = (event, value) => {
    setState((draft) => {
      draft.page = value;
    });
  };

  const handleClickOpen = (id) => {
    setState((draft) => {
      draft.isOpenid = id;
    });
  };

  const handleClose = () => {
    setState((draft) => {
      draft.isOpenid = null;
    });
  };
  const activityParked = (res) => {
    const newData = res?.data;
    const { order_id, date, token_number } = newData;
    const parameterData = {
      TicketNumber: token_number,
      OrderId: order_id,
      BumpScreenName: appState.data.name,
      StoreName: appState.data.store_name,
      EventDateTime: date,
    };
    addActivityLog({
      templateId: 15,
      parameters: parameterData,
      uniqueParameter: order_id,
      data: {
        newLogData: newData,
        oldLogData: {},
      },
    });
  };
  const activityCompleted = (res) => {
    const newData = res?.data;
    const { order_id, date, token_number } = newData;
    const parameterData = {
      TicketNumber: token_number,
      OrderId: order_id,
      BumpScreenName: appState.data.name,
      StoreName: appState.data.store_name,
      EventDateTime: date,
    };
    addActivityLog({
      templateId: 14,
      parameters: parameterData,
      uniqueParameter: order_id,
      data: {
        newLogData: newData,
        oldLogData: {},
      },
    });
  };
  const onChangeTicketStatus = async (status, id) => {
    try {
      setState((draft) => {
        draft.orderList = draft.orderList.filter((item) => item.id !== id);
        draft.busyCardId = id;
      });
      const res = await changeTicketStatus(id, status);
      if (res.success) {
        if (status === "parked") {
          activityParked(res);
        } else if (status === "completed") {
          activityCompleted(res);
        }
        setState((draft) => {
          draft.isOpenid = null;
          draft.busyCardId = null;
        });
      } else {
        errorMessage(res.message);
        getPreparingData({ initialLoad: false });
      }
    } catch (err) {
      errorMessage(err.response.message);
      getPreparingData({ initialLoad: false });
      setState((draft) => {
        draft.busyCardId = null;
      });
    }
  };

  const onChangeProductStatus = async (id, productId, status) => {
    try {
      const ticketLength = state.orderList
        .find((item) => item.id === id)
        .products.filter((product) => product.status === "preparing").length;
      const res = await changeTicketProductStatus(id, productId, status);
      if (res.success) {
        setState((draft) => {
          draft.orderList = draft.orderList.map((item) => {
            if (item.id === id) {
              return {
                ...res.data,
                product_by_category: formateByCategory(item),
                counter: calculateTimeDiff(item.start_time),
              };
            } else {
              return item;
            }
          });
        });
        if (ticketLength === 1 && status === "prepared") {
          onChangeTicketStatus("completed", id);
        }
      } else {
        errorMessage(res.message);
      }
    } catch (err) {
      errorMessage(err.response.message);
    }
  };

  const updateCounter = (id) => {
    setState((draft) => {
      draft.orderList = draft.orderList.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            counter: calculateTimeDiff(item.start_time),
          };
        } else {
          return item;
        }
      });
    });
  };
  const activityRecall = (res) => {
    const newData = res?.data;
    const { order_id, date, token_number } = newData;
    const parameterData = {
      TicketNumber: token_number,
      OrderId: order_id,
      StoreName: appState.data.store_name,
      BumpScreenName: appState.data.name,
    };
    addActivityLog({
      templateId: 16,
      parameters: parameterData,
      uniqueParameter: order_id,
      data: {
        newLogData: newData,
        oldLogData: {},
      },
    });
  };


  const onRecallLastParkedOrder = async () => {

    try {
      setState((draft) => {
        draft.isReCallLastBusy = true;
      });
      const res = await recallLastParkedOrder(data.id);
      if (res.success) {
        activityRecall(res);
        getPreparingData({ initialLoad: false, type: "recallLast" });

        // setState((draft) => {
        //   draft.orderList = [
        //     ...draft.orderList,
        //     {
        //       ...res.data,
        //       counter: calculateTimeDiff(res.data.start_time),
        //     },
        //   ];
        //   draft.isReCallLastBusy = false;
        // });
        // successMessage("Last completed ticket re-called successfully.");
      } else {
        errorMessage(res.message);
        setState((draft) => {
          draft.isReCallLastBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.message);
      setState((draft) => {
        draft.isReCallLastBusy = false;
      });
    }
  };

  return {
    data,
    state,
    scrollRef,
    fontSize,
    handleChangePage,
    handleClickOpen,
    handleClose,
    onChangeTicketStatus,
    onChangeProductStatus,
    updateCounter,
    onRecallLastParkedOrder,
    setState,
  };
};
