import React, { useContext, useEffect } from "react";
import {
  Box,
  Link,
  Stack,
  Typography,
  MenuItem,
  Menu,
  Button,
} from "@mui/material";
import { AppStateContext } from "../contexts";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useImmer } from "use-immer";
import Clock from 'react-live-clock';
import moment from "moment";

export const ContentLayout = (props) => {
  const {
    appState: { data, fontSize },
    setAppState,
  } = useContext(AppStateContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isLiveTickets = pathname === "/kot";
  const { children, scrollRef, onRecallLastParkedOrder } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = useImmer({
    leftDisable: false,
    rightDisable: false,
  });
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const TabsOptions = [
    {
      label: "Live Tickets",
      path: "/kot",
    },
    {
      label: "Parked Tickets",
      path: "/kot/parked-orders",
    },
    {
      label: "Completed Tickets",
      path: "/kot/completed-orders",
    },
  ];

  const toggleFullSceen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      handleClose();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        handleClose();
      }
    }
  };

  const handleScroll = (type) => {
    const width =
      type === "l"
        ? -scrollRef.current.clientWidth
        : scrollRef.current.clientWidth;
    scrollRef.current.scrollLeft += width;
  };

  useEffect(() => {
    if (isLiveTickets) {
      if (scrollRef.current?.scrollLeft === 0) {
        setState((draft) => {
          draft.leftDisable = true;
        });
      } else {
        setState((draft) => {
          draft.leftDisable = false;
        });
      }
      if (
        scrollRef.current?.clientWidth ===
        Math.round(
          scrollRef.current?.scrollWidth - scrollRef.current?.scrollLeft
        )
      ) {
        setState((draft) => {
          draft.rightDisable = true;
        });
      } else {
        setState((draft) => {
          draft.rightDisable = false;
        });
      }
    }
  }, [
    scrollRef?.current?.scrollLeft,
    scrollRef?.current?.clientWidth,
    scrollRef?.current?.clientHeight,
  ]);

  return (
    <Stack minHeight="100vh">
      <Stack
        direction="row"
        p="24px"
        alignItems="center"
        gap="24px"
        height="4rem"
        sx={{
          position: "sticky",
          top: "0",
          backgroundColor: "#ffffff",
          zIndex: "999",
          // borderBottom: " 1px solid #CCCCCC",
        }}
      >
        <Typography variant="h6" lineHeight="16px" pr="16px">
          {data.name}
        </Typography>

        {/* tabs */}
        <Stack direction="row" width="100%">
          {TabsOptions.map((data, i) => {
            const isSelected = pathname === data.path;
            return (
              <Stack
                key={i}
                sx={{
                  justifyContent: "center",
                  px: "16px",
                  py: "auto",
                  height: "64px",
                  backgroundColor: isSelected
                    ? "rgba(21, 96, 212, 0.08)"
                    : "#ffffff",
                  borderBottom: isSelected
                    ? "3px solid #1560D4;"
                    : "3px solid transparent",
                  cursor: "pointer",
                  fontWeight: isSelected ? 600 : 400,
                  fontSize: "16px",
                  lineHeight: "16px",
                  color: isSelected ? "#1560D4" : "#4D4D4D",
                  "&:hover": {
                    backgroundColor: "rgba(21, 96, 212, 0.08)",
                  },
                }}
                onClick={() => {
                  navigate(data.path);
                }}
              >
                {data.label}
              </Stack>
            );
          })}
        </Stack>

        {/* Recall and scroll button */}
        {isLiveTickets && (
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <LoadingButton
              // loading={isReCallLastBusy}
              color="info"
              onClick={() => {
                onRecallLastParkedOrder();
              }}
            >
              Recall Last
            </LoadingButton>

            <Box
              sx={{
                height: "36px",
                width: "1px",
                backgroundColor: "#CCCCCC",
                ml: "24px",
              }}
            />
            <Button
              color="info"
              sx={{
                minWidth: "48px",
                backgroundColor: "unset",
              }}
              onClick={() => handleScroll("l")}
              disabled={state.leftDisable}
            >
              <ArrowBackIosNewIcon />
            </Button>
            <Button
              color="info"
              sx={{
                minWidth: "48px",
                backgroundColor: "unset",
              }}
              onClick={() => handleScroll("r")}
              disabled={state.rightDisable}
            >
              <ArrowForwardIosIcon />
            </Button>
          </Stack>
        )}
        <Stack alignItems="flex-end" gap="4px" textAlign="end">
          <Typography width="max-content">
            {moment().tz(data.timezone).format('DD-MM-YYYY')}
          </Typography>
          <Clock
            format={'hh:mm:ss A'}
            ticking={true}
            timezone={data.timezone}
            style={{
              minWidth: "100px"
            }} />
        </Stack>


        {/* more button */}
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          color="info"
          sx={{
            minWidth: "60px",
            height: "48px",
          }}
        >
          <img alt="more" src="/images/ic_more.svg" />
        </Button>
        <Menu
          ty
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{
            "& .MuiPaper-root": {
              minWidth: "240px",
            },

            "& .MuiMenu-list": {
              padding: "12px 0",
              "& .MuiMenuItem-root": {
                gap: "8px",
                height: "40px",
                fontSize: "18px",
                lineHeight: "16px",
              },
            },
          }}
        >
          <MenuItem
            onClick={() => toggleFullSceen()}
            sx={{ borderBottom: "1px solid #E6E6E6" }}
          >
            <img
              alt="fullScreen"
              src={
                !document.fullscreenElement
                  ? "/images/ic_fullscreen.svg"
                  : "/images/ic_minimize.svg"
              }
            />
            {!document.fullscreenElement ? "Fullscreen" : "Exit Fullscreen"}
          </MenuItem>
          <MenuItem sx={{ borderBottom: "1px solid #E6E6E6" }}>
            <Stack
              direction="row"
              sx={{
                minHeight: "48px",
                mr: "-16px",
              }}
            >
              <Stack
                direction="row"
                gap="8px"
                width="100%"
                pr="6px"
                alignItems="center"
              >
                <img alt="fullScreen" src="/images/text-Size.svg" />
                <span>Font Size </span>
              </Stack>
              <Stack
                sx={{
                  minWidth: "48px",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid #E6E6E6",
                  cursor: fontSize === -50 && "not-allowed",
                }}
                onClick={() => {
                  if (fontSize > -50) {
                    setAppState((draft) => {
                      draft.fontSize = draft.fontSize - 25;
                    });
                    window.location.reload();
                  }
                }}
              >
                <img
                  alt="minus"
                  src="/images/Minus.svg"
                  style={{
                    filter:
                      fontSize === -50 &&
                      "brightness(0) saturate(100%) invert(61%) sepia(0%) saturate(0%) hue-rotate(151deg) brightness(82%) contrast(84%)",
                  }}
                />
              </Stack>

              <Stack
                minWidth="60px"
                justifyContent="center"
                alignItems="center"
                sx={{
                  minWidth: "60px",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: `1rem  `,
                  fontWeight: 500,
                }}
              >
                {100 + fontSize}%
              </Stack>
              <Stack
                sx={{
                  minWidth: "48px",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid #E6E6E6",
                  cursor: fontSize === 50 && "not-allowed",
                }}
                onClick={() => {
                  if (fontSize < 50) {
                    setAppState((draft) => {
                      draft.fontSize = draft.fontSize + 25;
                    });
                    window.location.reload();
                  }
                }}
              >
                <img
                  alt="plus "
                  src="/images/Add.svg"
                  style={{
                    filter:
                      fontSize === 50 &&
                      "brightness(0) saturate(100%) invert(61%) sepia(0%) saturate(0%) hue-rotate(151deg) brightness(82%) contrast(84%)",
                  }}
                />
              </Stack>
            </Stack>
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/kot/settings");
              handleClose();
            }}
          >
            <img alt="settings" src="/images/ic_settings.svg" />
            Settings
          </MenuItem>
        </Menu>
      </Stack>
      <Box
        sx={{
          backgroundColor: "#F3F3F3",
          height: "calc(100vh - 64px)",
          p: "10px",
        }}
      >
        {children}
      </Box>
      {/* <Stack
        m="auto 0 0 0"
        direction="row"
        p="8px"
        sx={{
          position: "sticky",
          bottom: "0",
          backgroundColor: "#ffffff",
          zIndex: "999",
          borderTop: " 1px solid #CCCCCC",
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            color: "#1F529D",
            fontWeight: 400,
            borderRight: "1px solid #E6E6E6",
            p: "0 16px",
            "&:first-child": {
              paddingLeft: 0,
            },
          }}
        >
          Total Jobs: {summary.total_jobs}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            color: "#1F529D",
            fontWeight: 400,
            borderRight: "1px solid #E6E6E6",
            p: "0 16px",
            "&:first-child": {
              paddingLeft: 0,
            },
          }}
        >
          Parked Jobs: {summary.parked_jobs}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            color: "#1F529D",
            fontWeight: 400,
            borderRight: "1px solid #E6E6E6",
            p: "0 16px",
            "&:first-child": {
              paddingLeft: 0,
            },
          }}
        >
          Total Items: {summary.total_items}
        </Typography>
      </Stack> */}
    </Stack>
  );
};
