import React, { useContext, useEffect } from "react";
import { AppStateContext, CustomeDialog } from "../../../shared";
import { Button, Chip, Stack, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { useKotSettings } from "../hooks/useKotSettings";

export function KOTSettings() {
  const {
    handleReset,
    state: { isBusy, data, isUnpairBusy, isUnpairOpen },
    toggleUnpair,
    onConfirmUnpair,
  } = useKotSettings();
  const [value, setValue] = React.useState(1);
  return (
    <>
      <Stack
        sx={{
          bgcolor: "#F2F2F2",
          height: "100%",
        }}
      >
        <Stack height="100%">
          <Stack
            sx={{
              height: "88px",
              bgcolor: "#fff",
              p: "24px",
              justifyContent: "center",
              borderBottom: "1px solid #E6E6E6",
            }}
          >
            <Typography variant="h4">Settings</Typography>
          </Stack>
          <Stack direction="row" height="100%">
            <Stack
              sx={{
                width: "200px",
                height: "100%",
                bgcolor: "#fff",
              }}
            >
              <Stack
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "8px",
                  height: "56px",
                  padding: "10px 10px 10px 24px",
                  background: value === 1 && "rgba(21, 96, 212, 0.08)",
                  cursor: "pointer",
                }}
                onClick={() => setValue(1)}
              >
                <img
                  alt="reset"
                  src="/images/ic_reset.svg"
                  style={{
                    height: "24px",
                    filter:
                      value === 1 &&
                      "invert(20%) sepia(100%) saturate(2716%) hue-rotate(213deg) brightness(93%) contrast(84%)",
                  }}
                />
                <Typography
                  variant="h6"
                  color={value === 1 ? "#1560D4" : "#000"}
                >
                  Reset
                </Typography>
              </Stack>
              <Stack
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "8px",
                  height: "56px",
                  padding: "10px 10px 10px 24px",
                  background: value === 2 && "rgba(21, 96, 212, 0.08)",
                  cursor: "pointer",
                }}
                onClick={() => setValue(2)}
              >
                <img
                  alt="about"
                  src="/images/ic_info.svg"
                  style={{
                    height: "24px",
                    filter:
                      value === 2 &&
                      "invert(20%) sepia(100%) saturate(2716%) hue-rotate(213deg) brightness(93%) contrast(84%)",
                  }}
                />
                <Typography
                  variant="h6"
                  color={value === 2 ? "#1560D4" : "#000"}
                >
                  About
                </Typography>
              </Stack>
            </Stack>
            <Stack p="16px 0 0 16px" width="100%">
              {value === 1 ? (
                <Stack width="100%">
                  <Stack
                    sx={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      bgcolor: "#fff",
                      p: "12px 24px",
                      width: "100%",
                    }}
                  >
                    <Typography variant="h6">Reset Bump Screen</Typography>
                    <Button
                      color="info"
                      sx={{
                        minWidth: "60px",
                        height: "48px",
                        fontWeight: 600,
                        fontSize: "14px",
                        lineHXeight: "17px",
                      }}
                      onClick={toggleUnpair}
                    >
                      Reset
                    </Button>
                  </Stack>
                </Stack>
              ) : (
                <Stack
                  sx={{
                    width: "100%",
                    height: "calc(100vh - 183px)",
                    overflow: "auto",
                  }}
                >
                  <Stack
                    sx={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      bgcolor: "#fff",
                      p: "12px 24px",
                      width: "100%",
                      minHeight: "72px",
                    }}
                  >
                    <Typography variant="h6">Store Name</Typography>
                    <Typography variant="h6" fontWeight={400}>
                      {data.store_name}
                    </Typography>
                  </Stack>

                  {data.conditions.length
                    ? data.conditions.map((item, i) => {
                        return (
                          <>
                            <Stack
                              sx={{
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                p: "12px 24px",
                                width: "100%",
                                minHeight: "72px",
                                backgroundColor: "#D9D9D900",
                              }}
                            >
                              <Typography variant="h6">
                                {item?.channel_name}
                              </Typography>
                            </Stack>
                            <Stack
                              sx={{
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                bgcolor: "#fff",
                                p: "12px 24px",
                                width: "100%",
                                borderBottom: "1px solid #CCCCCC",
                                minHeight: "72px",
                              }}
                            >
                              <Typography variant="h6">
                                Bump Screen Tags
                              </Typography>
                              <Stack direction="row" gap="10px">
                                {item?.tags?.length
                                  ? item?.tags.map((data, i) => (
                                      <Chip
                                        key={i}
                                        label={data}
                                        sx={{
                                          borderRadius: "8px",
                                          bgcolor: "rgba(0, 0, 0, 0.06)",
                                          color: "#171717",
                                          "& .MuiChip-label": {
                                            fontSize: "18px",
                                            lineHeight: "22px",
                                            fontWeight: 400,
                                          },
                                        }}
                                      />
                                    ))
                                  : ""}
                              </Stack>
                            </Stack>

                            <Stack
                              sx={{
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                bgcolor: "#fff",
                                p: "12px 24px",
                                width: "100%",
                                minHeight: "72px",
                              }}
                            >
                              <Typography variant="h6">Sales Types</Typography>
                              <Stack direction="row" gap="10px">
                                {item?.sale_types?.length
                                  ? item?.sale_types.map((data, i) => (
                                      <Chip
                                        key={i}
                                        label={data}
                                        sx={{
                                          bgcolor: "rgba(0, 0, 0, 0.06)",
                                          color: "#171717",
                                          "& .MuiChip-label": {
                                            fontSize: "18px",
                                            lineHeight: "22px",
                                            fontWeight: 400,
                                          },
                                        }}
                                      />
                                    ))
                                  : ""}
                              </Stack>
                            </Stack>
                          </>
                        );
                      })
                    : ""}
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <CustomeDialog
        open={isUnpairOpen}
        handleClose={toggleUnpair}
        PaperProps={{
          sx: { p: "24px 24px 16px 24px", minWidth: "432px" },
        }}
        title="Reset"
        content={
          <Stack sx={{ gap: "16px", paddingTop: "16px" }}>
            <Stack sx={{ padding: "16px 0 10px 0" }}>
              <Typography>Are you sure you want to Reset?</Typography>
            </Stack>

            <Stack direction="row" gap="12px" justifyContent="flex-end">
              <Button sx={{ borderRadius: "8px" }} onClick={toggleUnpair}>
                No
              </Button>
              <Button
                sx={{ borderRadius: "8px" }}
                onClick={onConfirmUnpair}
                variant="contained"
                disabled={isUnpairBusy}
              >
                Yes
              </Button>
            </Stack>
          </Stack>
        }
      />
    </>
  );
}
