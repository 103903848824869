import { useChannel, usePresence } from "@ably-labs/react-hooks";
import { Route, Routes } from "react-router-dom";
import { ContentLayout } from "../../shared";
import {
  CompletedOrdersList,
  KOTList,
  KOTSettings,
  ParkedOrdersList,
} from "./containers";
import { useKotList } from "./hooks/useKotList";

export const KOT = () => {
  const {
    data,
    state,
    scrollRef,
    fontSize,
    handleChangePage,
    handleClickOpen,
    handleClose,
    onChangeTicketStatus,
    onChangeProductStatus,
    updateCounter,
    onRecallLastParkedOrder,
  } = useKotList();

  const [presenceData] = usePresence("presence-kds", []);
  const [channel, ably] = useChannel(
    `public:bump-screen.${data.id}`,
    (message) => {
      const { name } = message;
      if (name === "BumpScreenUnPaired") {
        localStorage.removeItem("kot-data");
        window.location.href = "/";
      }
    }
  );
  return (
    <>
      <ContentLayout
        scrollRef={scrollRef}
        onRecallLastParkedOrder={onRecallLastParkedOrder}
      >
        <Routes>
          <Route
            path="/"
            element={
              <KOTList
                data={data}
                state={state}
                fontSize={fontSize}
                scrollRef={scrollRef}
                handleChangePage={handleChangePage}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                onChangeTicketStatus={onChangeTicketStatus}
                onChangeProductStatus={onChangeProductStatus}
                updateCounter={updateCounter}
              />
            }
          />
          <Route path="/parked-orders" element={<ParkedOrdersList />} />
          <Route path="/completed-orders" element={<CompletedOrdersList />} />
          <Route path="/settings" element={<KOTSettings />} />
        </Routes>
      </ContentLayout>
    </>
  );
};
